*{
    box-sizing: border-box;
}

body {
    margin:0;
    padding: 0;
}

@font-face {
    font-family: "Light-Italic";
    src: url(./font/RebondGrotesque/Rebond-Grotesque-Light-Italic.ttf);
}

@font-face {
    font-family: "Extra-Light";
    src: url(./font/RebondGrotesque/Rebond-Grotesque-Light.otf);
}

@font-face {
    font-family: "Light";
    src: url(./font/RebondGrotesque/Rebond-Grotesque.ttf);
}

@font-face {
    font-family: "Medium";
    src: url(./font/RebondGrotesque/Rebond-Grotesque-Medium.ttf);
}

@font-face {
    font-family: "Bold";
    src: url(./font/RebondGrotesque/Rebond-Grotesque-Bold.ttf);
}

@font-face {
    font-family: "ExtraBold";
    src: url(./font/RebondGrotesque/Rebond-Grotesque-Extrabold.ttf);
}

.ant-collapse-content-box{
    padding-top:0 !important;
}
.ant-collapse-content-active{
    border-radius: 15px;;
    border-top:none !important;
   
}
.ant-collapse-content-box p{
    margin-top: 0 !important;
}

.ant-collapse-item{
    border: 1px solid #D9D9D9 !important;
    margin-bottom: 20px !important;;
}

.ant-collapse{
    background-color: transparent !important;
}

.ant-collapse-header {
    height: 50px;
    margin-bottom: 0 !important;
  }

  .ant-collapse-content-box {
    height: auto !important;
    border-radius: 15px;
    margin: 0;
    font-family:Light;
    border: 1px solid #fff;
  }


  .ant-collapse-item,
  .ant-collapse-item-active {
    margin: 0 0 10px 0;
    border-radius: 15px;
    border: 1px solid #fff;
    background-color: #fff !important;
  }

  .react-multi-carousel-dot-list {
    position: absolute;
    left: 20px !important;
    height: 40px;
    width: 70px;
    justify-content: left !important;
    text-align: left !important;
  }

  .react-multiple-carousel__arrow--right {
    position: absolute;
    right: 0px;
    border: 2px solid #fff !important;
    margin-top:450px !important;
  }

  .react-multiple-carousel__arrow--left {
    position: absolute;
    border: 2px solid #fff !important;
    left: 410px !important;
    margin-top:450px !important;
    
  }