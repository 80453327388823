body {
  margin: 0;
  font-family: Rebound;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Rebound;
  src: url("./font/rebond-grotesque/Rebond\ Grotesque.otf");
  font-weight: 700;
}

@font-face {
  font-family: Rebound2;
  src: url("./font/rebond-grotesque/Rebond\ Grotesque\ Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: Rebound3;
  src: url("./font/rebond-grotesque/Rebond\ Grotesque\ Semibold.otf");
  font-weight: 700;
}

h3 {
  font-family: Rebound2;
}
h6 {
  font-family: Rebound2;
}

